// Getters
import { computed, ref, toRef, onMounted } from 'vue';
import form from './form';
import global from './global';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import take from 'lodash/take';
import values from 'lodash/values';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import responsive from './responsive';

const queryAgency = ref('');

const pageItemsConsultant = 5;
const showLimitConsultant = ref(5);
const initialShowLimitConsultant = 5;

const pageItemsAgency = 3;
const showLimitAgency = ref(3);

const showMoreButtonConsultant = computed(() => {
    return showLimitConsultant.value < getConsultants.value.length;
});
const showMoreButtonAgency = computed(() => {
    return showLimitAgency.value < getAgencies.value.length;
});

const getAgencies = computed(() => {
    const { state: { meeting: { consultants } } } = global;
    let { state : { meeting : { agencies } } } = global;

    let results = queryAgency.value.length < 2 ? agencies : filter(agencies, (agency) => {
        for (let property of ['name', 'city', 'postal_code']) {
            if (typeof agency[property] !== 'undefined'
                && agency[property].toLowerCase().includes(queryAgency.value.toLowerCase())
            ) {
                return true;
            }
        }

        return false;
    });

    results = orderBy(results, ['name'], ['asc']);

    results = map(results, (result, index) => {
        return {
            index: index + 1,
            ...result,
        };
    });

    return results;
});

const getSortedAgencies = computed(() => {
    const { state: { payload } } = form;
    const { bpUpMd } = responsive;
    const selectedAgency = toRef(payload, 'agency');

    let sortedAgency;
    if (selectedAgency.value) {
        // Put selected agency on top of list
        sortedAgency = sortBy(getAgencies.value, (result) => {
            return result.id !== selectedAgency.value.id;
        });
    } else {
        sortedAgency = getAgencies.value;
    }

    return !bpUpMd.value ? take(sortedAgency, showLimitAgency.value) : sortedAgency;
});

const getConsultants = computed(() => {
    const { state: { payload } } = form;
    const { state: { meeting } } = global;
    const selectedAgency = toRef(payload, 'agency');
    const agency_selection_enabled = toRef(meeting, 'agency_selection_enabled');

    if (!selectedAgency.value || !agency_selection_enabled.value) {
        return values(meeting.consultants);
    } else {
        return filter(values(meeting.consultants), (consultant) => {
            for (let consultantAgency of consultant.agencies) {
                if (consultantAgency.id == selectedAgency.value.id) {
                    return true;
                }
            }

            return false;
        });
    }
});

const getPagedConsultants = computed(() => {
    const { bpUpMd } = responsive;
    return !bpUpMd.value ? take(getConsultants.value, showLimitConsultant.value) : getConsultants.value;
});

const getFonctions = computed(() => {
    const filtered = filter(getConsultants.value, consultant => consultant.function);

    const uniq = uniqBy(filtered, 'function.id');

    return map(uniq, (o) => o.function);
});

// For showing on Summary
const getConsultant = computed(() => {
    const { state: { payload } } = form;
    const selectedConsultant = toRef(payload, 'consultant');

    return selectedConsultant.value ?? null;
});

// For showing on Summary
const getAgency = computed(() => {
    const { state: { payload } } = form;
    const selectedAgency = toRef(payload, 'agency');

    return selectedAgency.value ?? null;
});

function updateQueryAgency(value) {
    queryAgency.value = value;
}

function onShowMoreConsultant() {
    if (showMoreButtonConsultant.value) showLimitConsultant.value = showLimitConsultant.value + pageItemsConsultant;
}

function onShowMoreAgency() {
    if (showMoreButtonAgency.value) showLimitAgency.value = showLimitAgency.value + pageItemsAgency;
}

function setupConsultant() {
    const { state: { payload } } = form;
    const selectedConsultant = toRef(payload, 'consultant');
    showLimitConsultant.value = initialShowLimitConsultant;

    if (selectedConsultant.value) {
        const index = findIndex(getConsultants.value, item => item.id === selectedConsultant.value.id);
        if (index + 1 > initialShowLimitConsultant) showLimitConsultant.value = index + 1;

        onMounted(() => {
            const activeItem = document.querySelector('.consultant.active');
            if (activeItem) {
                window.scrollTo({
                    top: activeItem.getBoundingClientRect().top - 20,
                    behavior: 'smooth',
                });
            }
        });
    }
}

export default {
    getConsultants,
    getPagedConsultants,
    getConsultant,
    getFonctions,
    getAgency,
    getAgencies,
    getSortedAgencies,
    queryAgency,
    updateQueryAgency,
    onShowMoreConsultant,
    onShowMoreAgency,
    showMoreButtonConsultant,
    showMoreButtonAgency,
    setupConsultant,
};
